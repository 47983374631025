import React from "react";

const Icon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.11878 16.0785C8.11878 16.7135 7.9433 17.0683 7.74623 17.2654C7.54917 17.4624 7.19433 17.6379 6.55939 17.6379V19.6379C7.56246 19.6379 8.48732 19.3527 9.16045 18.6796C9.83357 18.0065 10.1188 17.0816 10.1188 16.0785H8.11878ZM6.55939 17.6379C5.92445 17.6379 5.56962 17.4624 5.37255 17.2654C5.17548 17.0683 5 16.7135 5 16.0785H3C3 17.0816 3.28521 18.0065 3.95833 18.6796C4.63146 19.3527 5.55632 19.6379 6.55939 19.6379V17.6379ZM5 16.0785C5 15.4436 5.17548 15.0888 5.37255 14.8917C5.56962 14.6946 5.92445 14.5191 6.55939 14.5191V12.5191C5.55632 12.5191 4.63146 12.8043 3.95833 13.4775C3.28521 14.1506 3 15.0755 3 16.0785H5ZM6.55939 14.5191C7.19433 14.5191 7.54917 14.6946 7.74623 14.8917C7.9433 15.0888 8.11878 15.4436 8.11878 16.0785H10.1188C10.1188 15.0755 9.83357 14.1506 9.16045 13.4775C8.48732 12.8043 7.56246 12.5191 6.55939 12.5191V14.5191Z" />
      <path d="M17.2377 16.0785C17.2377 16.7135 17.0622 17.0683 16.8651 17.2654C16.6681 17.4624 16.3132 17.6379 15.6783 17.6379V19.6379C16.6814 19.6379 17.6062 19.3527 18.2793 18.6796C18.9525 18.0065 19.2377 17.0816 19.2377 16.0785H17.2377ZM15.6783 17.6379C15.0434 17.6379 14.6885 17.4624 14.4914 17.2654C14.2944 17.0683 14.1189 16.7135 14.1189 16.0785H12.1189C12.1189 17.0816 12.4041 18.0065 13.0772 18.6796C13.7504 19.3527 14.6752 19.6379 15.6783 19.6379V17.6379ZM14.1189 16.0785C14.1189 15.4436 14.2944 15.0888 14.4914 14.8917C14.6885 14.6946 15.0434 14.5191 15.6783 14.5191V12.5191C14.6752 12.5191 13.7504 12.8043 13.0772 13.4775C12.4041 14.1506 12.1189 15.0755 12.1189 16.0785H14.1189ZM15.6783 14.5191C16.3132 14.5191 16.6681 14.6946 16.8651 14.8917C17.0622 15.0888 17.2377 15.4436 17.2377 16.0785H19.2377C19.2377 15.0755 18.9525 14.1506 18.2793 13.4775C17.6062 12.8043 16.6814 12.5191 15.6783 12.5191V14.5191Z" />
      <path d="M26.3563 16.0785C26.3563 16.7135 26.1808 17.0683 25.9838 17.2654C25.7867 17.4624 25.4319 17.6379 24.7969 17.6379V19.6379C25.8 19.6379 26.7249 19.3527 27.398 18.6796C28.0711 18.0065 28.3563 17.0816 28.3563 16.0785H26.3563ZM24.7969 17.6379C24.162 17.6379 23.8072 17.4624 23.6101 17.2654C23.413 17.0683 23.2375 16.7135 23.2375 16.0785H21.2375C21.2375 17.0816 21.5228 18.0065 22.1959 18.6796C22.869 19.3527 23.7939 19.6379 24.7969 19.6379V17.6379ZM23.2375 16.0785C23.2375 15.4436 23.413 15.0888 23.6101 14.8917C23.8072 14.6946 24.162 14.5191 24.7969 14.5191V12.5191C23.7939 12.5191 22.869 12.8043 22.1959 13.4775C21.5228 14.1506 21.2375 15.0755 21.2375 16.0785H23.2375ZM24.7969 14.5191C25.4319 14.5191 25.7867 14.6946 25.9838 14.8917C26.1808 15.0888 26.3563 15.4436 26.3563 16.0785H28.3563C28.3563 15.0755 28.0711 14.1506 27.398 13.4775C26.7249 12.8043 25.8 12.5191 24.7969 12.5191V14.5191Z" />
    </svg>
  );
};

export default Icon;
