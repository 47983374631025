import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { Link } from "react-router-dom";
import { NewsApi } from "utils/api";
import { userSelect } from "redux/selectors/user";
import { ROUTES_PATH } from "constants/routesPath";
import "swiper/css";
import "swiper/css/pagination";
import { useSelector } from "react-redux";

SwiperCore.use([Pagination]);

const HomePageSlider = () => {
  const { user_info } = useSelector(userSelect);
  const [news, setNews] = useState([]);

  useEffect(() => {
    NewsApi.getNewsList().then((res) => setNews(res));
  }, []);

  return (
    <section className="discounts">
      <Swiper
        slidesPerView={1.03}
        spaceBetween={12}
        className="discounts-slider"
        modules={[Pagination]}
        pagination={{
          el: ".slider-pagination",
          clickable: true,
        }}
      >
        {news &&
          news.map((slide, key) => (
            <SwiperSlide key={key}>
              <Link
                to={
                  user_info.id
                    ? slide.url
                      ? slide.url
                      : `/news/${slide.id}`
                    : ROUTES_PATH.REGISTRATION
                }
              >
                <div className="discounts-slider__img">
                  <img src={slide.image} alt="Изображение скидки" />
                </div>
              </Link>
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="slider-pagination"></div>
    </section>
  );
};

export default HomePageSlider;
