import React, { memo, useCallback } from "react";
import { IMaskInput } from "react-imask";

const Input = memo(
  ({
    label,
    id,
    type = "text",
    value,
    setValue,
    placeholder = "",
    mask,
    disabled = false,
    children,
    extraClass,
  }) => {
    const handleChange = useCallback((e) => setValue(e.target.value), []);

    return (
      <div className={`form-input ${extraClass ? extraClass : ""}`}>
        <label htmlFor={id}>{label}</label>
        <IMaskInput
          mask={mask}
          radix="."
          placeholder={placeholder}
          type={type}
          id={id}
          onChange={(e) => handleChange(e)}
          value={value}
          disabled={disabled}
        />
        {children}
      </div>
    );
  }
);

export default Input;
