import "./styles/scss/style.scss";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import Home from "pages/Home";
import Registration from "pages/Registration";
import Profile from "pages/Profile";
import Compliment from "pages/Compliment";
import Account from "pages/Account";
import AboutProgram from "pages/AboutProgram";
import UserCompliments from "pages/account/UserCompliments";
import InviteFriend from "pages/account/InviteFriend";
import Story from "pages/account/Story";
import Contact from "pages/account/Contact";
import UserCompliment from "pages/account/UserCompliment";
import WheelPage from "pages/WheelPage";
import Lose from "pages/Lose";
import Award from "pages/Award";
import Root from "components/Root";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index path={ROUTES_PATH.HOME} element={<Home />} />
      <Route path={ROUTES_PATH.REGISTRATION} element={<Registration />} />
      <Route path={ROUTES_PATH.PROFILE} element={<Profile />} />
      <Route path={ROUTES_PATH.COMPLIMENT} element={<Compliment />} />
      <Route
        path={ROUTES_PATH.ACCOUNT_COMPLIMENT}
        element={<UserCompliment />}
      />
      <Route path={ROUTES_PATH.NEWS} element={<Compliment news />} />
      <Route path={ROUTES_PATH.ACCOUNT} element={<Account />} />
      <Route path={ROUTES_PATH.ABOUT_PROGRAM} element={<AboutProgram />} />
      <Route
        path={ROUTES_PATH.USER_COMPLIMENTS}
        element={<UserCompliments />}
      />
      <Route path={ROUTES_PATH.INVITE_FRIEND} element={<InviteFriend />} />
      <Route path={ROUTES_PATH.STORY} element={<Story />} />
      <Route path={ROUTES_PATH.CONTACT} element={<Contact />} />
      <Route path={ROUTES_PATH.SPINNING_WHEEL} element={<WheelPage />} />
      <Route path={ROUTES_PATH.SPINNING_WHEEL_AWARD} element={<Award />} />
      <Route path={ROUTES_PATH.SPINNING_WHEEL_LOSE} element={<Lose />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
