import { createAsyncThunk } from "@reduxjs/toolkit";
import { BonusesApi } from "utils/api";

export const fecthBonuses = createAsyncThunk(
  "bonuses/fetchBonuses",
  async ({ category, user_id }) => {
    const data = await BonusesApi.getBonusesList({
      bonus_type_id: 1,
      user_id,
      cat_id: category,
    });

    return data;
  }
);

export const fetchBonusesCategories = createAsyncThunk(
  "bonuses/fetchBonusesCategories",
  async () => {
    const data = await BonusesApi.getBonusesCategories();

    return data;
  }
);
