import React from "react";

const SectionSubtitle = ({ text, center, extraClass }) => {
  return (
    <h4
      className={`section-subtitle ${extraClass ? extraClass : ""} 
      ${center ? "section-subtitle--centered" : ""}`}
      dangerouslySetInnerHTML={{ __html: text }}
    ></h4>
  );
};

export default SectionSubtitle;
