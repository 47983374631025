import FormSelect from "components/formElements/FormSelect";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";
import Input from "components/formElements/Input";
import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserApi } from "utils/api";
import { userSelect } from "redux/selectors/user";
import { api_key } from "utils/api";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";
import { popupProvider } from "contextProviders/popupProvider";
import { useCookies } from "react-cookie";

const genderOptions = [
  { value: 1, label: "Мужской" },
  { value: 2, label: "Женский" },
];

const ProfileForm = ({ image }) => {
  const dispatch = useDispatch();
  const { user_info, auth_key } = useSelector(userSelect);
  const { setPopupOpen, setPopupContent } = useContext(popupProvider);
  const [cookies] = useCookies(["auth_key", "user_id"]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState(null);
  const [birth, setBirth] = useState("");
  const [dataSending, setDataSending] = useState(false);

  useEffect(() => {
    dispatch(
      fetchUserInfo({ auth_key: cookies.auth_key, user_id: cookies.user_id })
    );
  }, []);

  useEffect(() => updateUserInfo(), [user_info]);

  const handleSave = () => {
    setDataSending(true);

    const data = new FormData();

    data.append("user_id", user_info?.id);
    data.append("auth_key", auth_key);
    data.append("api_key", api_key);
    data.append("mall_id", 2);
    if (name) data.append("fullname", name);
    if (email) data.append("email", email.trim());
    if (gender?.value) data.append("gender", gender?.value);
    if (birth) data.append("birthday", birth);
    if (image) data.append("image", image);

    UserApi.edit(data)
      .then(() => {
        dispatch(fetchUserInfo({ auth_key, user_id: user_info.id }));
        setPopupContent({
          title: "Сохранено",
          children: (
            <p className="text text--centered">Изменения успешно сохранены</p>
          ),
          acceptButton: {
            text: "Закрыть",
            callback: () => setPopupOpen(false),
          },
        });
        setPopupOpen(true);
      })
      .catch((e) => {
        setPopupContent({
          error: e.response.data.error,
        });
        setPopupOpen(true);
      })
      .finally(() => setDataSending(false));
  };

  const updateUserInfo = () => {
    setName(user_info.fullname);
    setEmail(user_info.email);
    setGender(
      user_info.gender
        ? genderOptions.filter((obj) => obj.value === user_info.gender)
        : null
    );
    setBirth(user_info.birthday);
  };

  return (
    <form className="profile-form">
      <div className="profile-form__fields">
        <Input
          mask={String}
          id="name"
          label="Ваше имя"
          setValue={setName}
          value={name}
        />
        <Input
          id="phone"
          mask={"+{7}(000)000-00-00"}
          disabled
          label="Телефон"
          value={user_info.msisdn}
        />
        <Input
          mask={String}
          id="email"
          label="E-mail"
          setValue={setEmail}
          value={email}
          extraClass={
            user_info.email_confirm === 0 && email && "form-input--email"
          }
          children={
            user_info.email_confirm === 0 &&
            email && (
              <span className="account-navigation__item-message account-navigation__item-message--email">
                подтвердите ваш e-mail
              </span>
            )
          }
        />
        <FormSelect
          id="gender"
          label="Пол"
          options={genderOptions}
          setValue={setGender}
          value={gender}
        />
        <Input
          id="birth"
          label="День рождения"
          setValue={setBirth}
          value={birth}
          mask={"0000{-}00{-}00"}
          placeholder="ГГГГ-ММ-ДД"
        />
      </div>

      <PrimaryButton
        disabled={dataSending}
        long
        text={"Сохранить"}
        callback={handleSave}
      />
      <SecondaryButton
        text={"Заполнить позже"}
        href={`/account/${user_info.id}`}
      />
    </form>
  );
};

export default ProfileForm;
