import { ROUTES_PATH } from "constants/routesPath";
import SpinButton from "../components/buttons/SpinButton";
import React from "react";

const Lose = () => {
  return (
    <div className="container">
      <div className="test test--award" style={{ marginBottom: "3rem" }}>
        <div className="test__content">
          <div className="award">
            <h1 className="award__title award__title--less-margin">
              Участвуйте в Розыгрыше призов!
            </h1>

            <h4 className="award__subtitle">
              Благодарим за участие. Сожалеем, но в этот раз Вы не выиграли.
            </h4>

            <SpinButton text={`Вернуться назад`} href={ROUTES_PATH.HOME} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lose;
