import Bonuses from "components/Bonuses";
import PrimaryButton from "components/buttons/PrimaryButton";
import { popupProvider } from "contextProviders/popupProvider";
import React, { memo, useCallback, useContext, useState } from "react";
import GiftIcon from "icons/Gift";
import GiftContent from "components/popupContent/GiftContent";
import BuyComplimentForm from "components/popupContent/BuyComplimentForm";
import { useSelector, useDispatch } from "react-redux";
import { userSelect } from "redux/selectors/user";
import { BonusesApi } from "utils/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";
import { ROUTES_PATH } from "constants/routesPath";

const ComplimentContent = memo(
  ({ isActivated, bonus, news, updateBonusState, payed = false }) => {
    const navigate = useNavigate();
    const { user_info, auth_key } = useSelector(userSelect);
    const { setPopupOpen, setPopupContent } = useContext(popupProvider);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { pathname } = useLocation();
    const [dataSending, setDataSending] = useState(false);

    const dateTo = new Date(
      bonus?.period_to || news.period_till
    ).toLocaleDateString();

    const callbackSuccessAction = (tr_id, active) => {
      dispatch(fetchUserInfo({ auth_key, user_id: user_info.id }));
      updateBonusState();
      setPopupOpen(false);

      if (pathname === `/compliment/${id}` && active) {
        navigate(`/account/${user_info.id}/compliment/${tr_id}`);
      }
    };

    const handleActivateModalOpen = () => {
      setPopupContent({
        title: "С вашего бонусного счета списано",
        children: (
          <BuyComplimentForm
            bonus_id={bonus.id}
            callbackSuccessAction={callbackSuccessAction}
            bonuses={bonus?.price}
          />
        ),
      });
      setPopupOpen(true);
    };

    const handlePayModalOpen = useCallback(() => {
      const handlePayAccept = () => {
        setDataSending(true);
        BonusesApi.bonusRequest({ auth_key, user_id: user_info.id, id })
          .then((res) => {
            const activated = res.activated === 1;
            callbackSuccessAction(res.id, activated);

            if (!activated) handleActivateModalOpen();
          })
          .catch((e) => {
            setPopupContent({
              error: e.response.data.error,
            });
            setPopupOpen(true);
          })
          .finally(() => setDataSending(false));
      };

      if (user_info?.balance >= bonus?.price) {
        setPopupContent({
          title: "Получить подарок?",
          acceptButton: {
            text: "Подтвердить",
            callback: handlePayAccept,
            disabled: dataSending,
          },
          cancelButton: {
            text: "Отмена",
          },
          icon: <GiftIcon />,
          children: <GiftContent bonuses={bonus?.price} />,
        });

        setPopupOpen(true);
      } else {
        setPopupContent({
          error: "Недостаточно бонусов",
        });
        setPopupOpen(true);
      }
    }, [bonus, dataSending]);

    return (
      <section className="compliment-content">
        <div className="container">
          <div className="compliment-content__row">
            {!isActivated && bonus && <Bonuses bonusesCount={bonus?.price} />}
            {bonus?.bonus_left && (
              <div className={"compliment-content__left"}>
                Осталось {bonus?.bonus_left}
              </div>
            )}
          </div>
          <h1 className="compliment-content__title">
            {bonus?.title || news?.title}
          </h1>
          <div className="compliment-content__info">
            {(bonus?.place_title || news?.place_title) && (
              <div className="compliment-content__shop">
                {bonus?.place_title || news?.place_title}
              </div>
            )}
            <div className="compliment-content__term">до {dateTo}</div>
          </div>
          <div
            className="compliment-content__text"
            dangerouslySetInnerHTML={{ __html: bonus?.text || news?.content }}
          ></div>
          <div className="compliment-content__pay">
            {auth_key
              ? bonus &&
                !isActivated && (
                  <>
                    <PrimaryButton
                      purple
                      text={payed ? "Активировать" : "Оплатить"}
                      component={
                        !payed && <Bonuses bonusesCount={bonus?.price} />
                      }
                      callback={
                        payed ? handleActivateModalOpen : handlePayModalOpen
                      }
                    />

                    {user_info?.balance < bonus?.price && (
                      <span className="compliment-content__message">
                        Вам осталось накопить{" "}
                        {bonus?.price - user_info?.balance} бонусов
                      </span>
                    )}
                  </>
                )
              : !news && (
                  <PrimaryButton
                    purple
                    text={"Оплатить"}
                    component={<Bonuses bonusesCount={bonus?.price} />}
                    callback={() => navigate(ROUTES_PATH.REGISTRATION)}
                  />
                )}
          </div>
        </div>
      </section>
    );
  }
);

export default ComplimentContent;
