import React, { memo } from "react";
import Bonuses from "./Bonuses";
import DangerIcon from "icons/Danger";
import TimeIcon from "icons/Time";

const StoryItem = memo(
  ({ bonus, created, place, event, tr_value, receipt }) => {
    const dateOptions = {
      minute: "2-digit",
      hour: "2-digit",
      month: "long",
      day: "2-digit",
    };

    const receipt_error = receipt?.receipt_error;
    const receipt_status = receipt?.receipt_status;
    const sum = receipt?.sum;

    const renderReceiptTitle = () => {
      if (receipt_status === "Начислено") {
        return `Покупка в ${place.title}`;
      } else if (receipt_status === "В обработке") {
        return receipt_status;
      } else if (receipt_error) {
        return receipt_error;
      }
    };

    const date = new Date(created).toLocaleString([], dateOptions);

    return (
      <li className={`story-item ${receipt_error ? "story-item--error" : ""}`}>
        <div className="story-item__left">
          <h5 className="story-item__title">
            {receipt ? renderReceiptTitle() : bonus?.title}
            {event && event.title}
          </h5>
          <p className="story-item__text">
            <span>{date}</span>
            {sum && <span>Сумма покупки {sum} ₽</span>}
          </p>
        </div>
        <div className="story-item__right">
          {tr_value !== null && tr_value !== 0 && (
            <Bonuses bonusesCount={tr_value > 0 ? `+${tr_value}` : tr_value} />
          )}
          {receipt_error && <DangerIcon />}
          {receipt_status === "В обработке" && <TimeIcon />}
        </div>
      </li>
    );
  }
);

export default StoryItem;
