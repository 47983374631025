import React from "react";
import InviteFriendContent from "layout/inviteFriend/InviteFriendContent";

const InviteFriend = () => {
  return (
    <main className="main">
      <InviteFriendContent />
    </main>
  );
};

export default InviteFriend;
