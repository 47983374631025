import React from "react";
import ContactContent from "layout/contact/ContactContent";

const Contact = () => {
  return (
    <main className="main">
      <ContactContent />
    </main>
  );
};

export default Contact;
