import { sha256 } from "js-sha256";

export const handleRedirect = (phone) => {
  const hostName = "https://receipt-voyage.jetcrm.ru/";
  const currentPage = window.location.href;
  const salt = "NhTmpgZron2LLoa2erGT";

  window.location.href = `${hostName}?msisdn=${phone}&mall_id=2&signature=${sha256(
    phone + salt
  )}&redirect=${encodeURIComponent(currentPage)}`;
};
