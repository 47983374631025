import BecomeMember from "layout/home/BecomeMember";
import HomePageSlider from "components/sliders/HomePageSlider";
import Hero from "layout/home/Hero";
import React from "react";
import Compliments from "layout/home/Compliments";
import ScanButton from "components/buttons/ScanButton";
import ProfileNavigation from "layout/home/ProfileNavigation";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";

const Home = () => {
  const { auth_key } = useSelector(userSelect);

  return (
    <main className="main">
      <Hero />
      <HomePageSlider />
      {auth_key !== "" ? <ProfileNavigation /> : <BecomeMember />}
      <Compliments />
      <ScanButton />
    </main>
  );
};

export default Home;
