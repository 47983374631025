import React from "react";

const GiftContent = ({ bonuses }) => {
  return (
    <div className="compliment-content__popup-message">
      С вашего счета будет списано
      <span>{bonuses} бонуов</span>
    </div>
  );
};

export default GiftContent;
