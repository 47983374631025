import React, { memo } from "react";
import { Link } from "react-router-dom";

const SpinButton = memo(
  ({ text, callback, disabled = false, white = false, href }) => {
    return (
      <>
        {href ? (
          <Link
            to={href}
            className={`spin-button ${white ? "spin-button--white" : ""}`}
          >
            {text}
          </Link>
        ) : (
          <button
            disabled={disabled}
            onClick={callback}
            className={`spin-button ${white ? "spin-button--white" : ""}`}
          >
            {text}
          </button>
        )}
      </>
    );
  }
);

export default SpinButton;
