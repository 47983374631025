import RegistrationContent from "layout/registration/RegistrationContent";
import React from "react";

const Registration = () => {
  return (
    <main className="main">
      <RegistrationContent />
    </main>
  );
};

export default Registration;
