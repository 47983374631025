import React, { memo } from "react";
import { Link } from "react-router-dom";

const SecondaryButton = memo(({ href, callback, text, small, purple }) => {
  const classNames = `secondary-button ${
    small ? "secondary-button--small" : ""
  } ${purple ? "secondary-button--purple" : ""}`;

  return (
    <>
      {href ? (
        <Link className={classNames} to={href}>
          {text}
        </Link>
      ) : (
        <button
          className={classNames}
          onClick={(e) => {
            e.preventDefault();
            callback();
          }}
        >
          {text}
        </button>
      )}
    </>
  );
});

export default SecondaryButton;
