import { fetchService } from "redux/asyncActions/fetchService";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  service: {},
};

const ServiceSlice = createSlice({
  name: "service",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchService.fulfilled, (state, action) => {
      state.service = action.payload;
    });

    builder.addCase(fetchService.rejected, (state) => {
      state.service = {};
    });
  },
});

export default ServiceSlice.reducer;
