import GoBackButton from "components/GoBackButton";
import SectionTitle from "components/titles/SectionTitle";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StoryItem from "components/StoryItem";
import { TransactionApi } from "utils/api";
import { userSelect } from "redux/selectors/user";
import { useSelector } from "react-redux/es/exports";

const StoryContent = () => {
  const navigate = useNavigate();
  const { user_info, auth_key } = useSelector(userSelect);
  const { id } = useParams();

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => document.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    setIsLastPage(false);
    fetchTransactions();
  }, []);

  useEffect(() => {
    if (fetching) {
      fetchTransactions();
    }
  }, [fetching]);

  const fetchTransactions = () => {
    TransactionApi.getTransactions({
      auth_key,
      user_id: user_info.id,
      page,
    })
      .then((res) => {
        if (res.length === 0) setIsLastPage(true);
        setItems([...items, ...res]);
        setPage((prev) => prev + 1);
      })
      .finally(() => setFetching(false));
  };

  const goBackCallback = useCallback(() => navigate(`/account/${id}`), []);

  const handleScroll = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) <
        100 &&
      !isLastPage
    ) {
      setFetching(true);
    }
  };

  return (
    <section className="story">
      <div className="container">
        <GoBackButton callback={goBackCallback} />
        <div>
          <SectionTitle text="История начислений" extraClass={"story__title"} />
        </div>
      </div>
      <div className="story__content">
        <ul className="story__list">
          {items.length > 0
            ? items.map((item, key) => <StoryItem {...item} key={key} />)
            : !fetching && (
                <div className="container">
                  <p className="text">Транзакций нет.</p>
                </div>
              )}
        </ul>

        {fetching && (
          <div className="container">
            <p className="text">Загрузка...</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default StoryContent;
