import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserApi } from "utils/api";

export const fetchUserInfo = createAsyncThunk(
  "bonuses/fetchUserInfo",
  async ({ user_id, auth_key }) => {
    const data = await UserApi.getData({ user_id, auth_key });

    return data;
  }
);
