import Categories from "components/Categories";
import SectionTitle from "components/titles/SectionTitle";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ComplimentCard from "components/ComplimentCard";
import {
  fecthBonuses,
  fetchBonusesCategories,
} from "redux/asyncActions/fetchBonuses";
import { userSelect } from "redux/selectors/user";

// icons
import MenuIcon from "icons/Categories/Menu";

const categoriesItems = [
  {
    title: "Все",
    icon: <MenuIcon />,
    id: null,
  },
];

const Compliments = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const { user_info } = useSelector(userSelect);
  const { categories, bonuses } = useSelector((state) => state.bonuses);

  const [activeCategory, setActiveCategory] = useState(null);

  useEffect(() => {
    dispatch(fetchBonusesCategories());
    dispatch(
      fecthBonuses({
        category: activeCategory,
        user_id: user_info.id ? user_info.id : null,
      })
    );

    isMounted.current = true;
  }, []);

  useEffect(() => {
    if (isMounted.current === true && bonuses) {
      dispatch(
        fecthBonuses({
          category: activeCategory,
          user_id: user_info.id ? user_info.id : null,
        })
      );
    }
  }, [activeCategory]);

  return (
    <section className="compliments">
      <div className="container">
        <SectionTitle
          extraClass={"compliments__title"}
          text={"Комплименты"}
          underlined
        />
      </div>

      {categories.length > 0 && (
        <Categories
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          items={[...categoriesItems, ...categories]}
        />
      )}

      <div className="container">
        <div className="compliments-list">
          {bonuses.length > 0 &&
            bonuses.map((bonus) => (
              <ComplimentCard {...bonus} key={bonus.id} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Compliments;
