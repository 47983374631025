import ProfileNavigation from "layout/home/ProfileNavigation";
import Hero from "layout/home/Hero";
import React from "react";
import AccountNavigation from "layout/account/AccountNavigation";
import ScanButton from "components/buttons/ScanButton";

const Account = () => {
  return (
    <main className="main">
      <Hero />
      <ProfileNavigation account />
      <AccountNavigation />
      <ScanButton top={200} />
    </main>
  );
};

export default Account;
