import GoBackButton from "components/GoBackButton";
import SectionTitle from "components/titles/SectionTitle";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactForm from "components/forms/ContactForm";

const ContactContent = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const goBackCallback = useCallback(() => navigate(`/account/${id}`), []);

  return (
    <section className="contact">
      <div className="container">
        <GoBackButton callback={goBackCallback} />

        <div className="contact__content">
          <SectionTitle text={"Обратная связь"} extraClass={"contact__title"} />

          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactContent;
