import StoryContent from "layout/story/StoryContent";
import React from "react";

const Story = () => {
  return (
    <main className="main">
      <StoryContent />
    </main>
  );
};

export default Story;
