export const ROUTES_PATH = {
  HOME: "/",
  REGISTRATION: "/registration",
  LOGIN: "/login",
  SCAN: "/account/:id/scan",
  PROFILE: "/account/:id/profile",
  ACCOUNT: "/account/:id",
  COMPLIMENT: "/compliment/:id",
  ABOUT_PROGRAM: "/account/about-program",
  USER_COMPLIMENTS: "/account/:id/compliments",
  INVITE_FRIEND: "/account/:id/invite-friend",
  STORY: "/account/:id/story",
  CONTACT: "/account/:id/contact",
  NEWS: "/news/:id",
  ACCOUNT_COMPLIMENT: "/account/:account_id/compliment/:compliment_id",
  SPINNING_WHEEL: "/wheel/:id",
  SPINNING_WHEEL_AWARD: "/wheel/win",
  SPINNING_WHEEL_LOSE: "/wheel/lose",
};
