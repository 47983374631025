import ComplimentHeader from "layout/compliment/ComplimentHeader";
import React, { useEffect, useState } from "react";
import ComplimentContent from "layout/compliment/ComplimentContent";
import { BonusesApi, NewsApi, TransactionApi } from "utils/api";
import { useLocation, useParams } from "react-router-dom";
import { userSelect } from "redux/selectors/user";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";
import { useCookies } from "react-cookie";

const Compliment = ({ news = false }) => {
  const { user_info, auth_key } = useSelector(userSelect);
  const [cookies] = useCookies(["auth_key", "user_id"]);
  const dispatch = useDispatch();

  const [bonus, setBonus] = useState(null);
  const [userBonuses, setUserBonuses] = useState([]);
  const [isPayed, setIsPayed] = useState(false);
  const [newsItem, setNewsItem] = useState(null);
  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (cookies.auth_key) {
      dispatch(
        fetchUserInfo({ user_id: cookies.user_id, auth_key: cookies.auth_key })
      );
    }

    if (news) {
      NewsApi.getNewsItem({
        id,
        user_id: user_info.id ? user_info.id : null,
      }).then((res) => setNewsItem(res));
    } else {
      updateBonusState();
    }
  }, []);

  useEffect(() => {
    if (userBonuses.length > 0) {
      userBonuses.forEach((bonus) => {
        console.log(bonus.bonus_id);
        if (bonus.bonus_id === Number(id)) {
          setIsPayed(true);
        }
      });
    }
  }, [userBonuses]);

  useEffect(() => {
    if (user_info && bonus) {
      TransactionApi.getTransactionBonuses({
        user_id: user_info.id ? user_info.id : null,
        auth_key,
        activated: 0,
      }).then((res) => {
        setUserBonuses(res);
      });
    }
  }, [user_info, bonus]);

  const updateBonusState = () => {
    BonusesApi.getBonus({
      id,
      user_id: user_info.id ? user_info.id : null,
    }).then((res) => setBonus(res));
  };

  return (
    <main className="main main--white">
      {(bonus || newsItem) && (
        <>
          <ComplimentHeader
            prevPath={state?.prevPath}
            news={newsItem}
            bonus={bonus}
          />
          <ComplimentContent
            news={newsItem}
            bonus={bonus}
            updateBonusState={updateBonusState}
            payed={isPayed}
            isActivated={false}
          />
        </>
      )}
    </main>
  );
};

export default Compliment;
