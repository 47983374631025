import PrimaryButton from "components/buttons/PrimaryButton";
import React, { useEffect } from "react";
import InfoIcon from "icons/Info";
import Bonuses from "components/Bonuses";
import { useSelector, useDispatch } from "react-redux";
import { userSelect } from "redux/selectors/user";
import { handleRedirect } from "utils/redirectToScanApp";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";

const ProfileNavigation = ({ account }) => {
  const { user_info } = useSelector(userSelect);
  const [cookies] = useCookies(["auth_key", "user_id"]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookies.auth_key)
      dispatch(
        fetchUserInfo({ user_id: cookies.user_id, auth_key: cookies.auth_key })
      );
  }, []);

  return (
    <div className="container">
      <div className="profile-navigation">
        <div className="profile-navigation__block">
          <div className="profile-navigation__top">
            <div className="profile-navigation__avatar">
              <img
                src={user_info.avatar ? user_info.avatar : "/img/avatar.png"}
                alt="Аватар профиля"
              />
            </div>
            <div className="profile-navigation__bonuses">
              <span>Бонусы</span>
              <Bonuses bonusesCount={user_info.balance} />
            </div>
          </div>
          <div className="profile-navigation__hello">
            Привет
            {user_info.fullname && `, ${user_info.fullname}`}
          </div>
          <PrimaryButton
            small
            light
            outlined
            text={account ? "Редактировать" : "Личный кабинет"}
            href={
              account
                ? `/account/${user_info.id}/profile`
                : `/account/${user_info.id}`
            }
          />
        </div>

        <div className="profile-navigation__block">
          <Link
            to={ROUTES_PATH.ABOUT_PROGRAM}
            className="profile-navigation__info"
          >
            <InfoIcon />
          </Link>

          <p className="profile-navigation__text">
            Сканируйте чеки и получайте бонусы за покупки. За каждые 100 ₽
            — 1 бонус.
          </p>

          <PrimaryButton
            small
            outlined
            light
            text="Сканировать чек"
            callback={() => handleRedirect(user_info.msisdn)}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileNavigation;
