import React from "react";

const Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3346 0.000198364H5.6656C2.26802 0.000198364 0.000595093 2.43279 0.000595093 5.9162V14.0842C0.000595093 17.5709 2.26196 20.0002 5.6656 20.0002H14.3336C17.7382 20.0002 20.0006 17.5709 20.0006 14.0842V5.9162C20.0006 2.4297 17.7384 0.000198364 14.3346 0.000198364ZM5.6656 1.5002H14.3346C16.8851 1.5002 18.5006 3.23516 18.5006 5.9162V14.0842C18.5006 16.7654 16.885 18.5002 14.3336 18.5002H5.6656C3.11535 18.5002 1.5006 16.7655 1.5006 14.0842V5.9162C1.5006 3.23857 3.12093 1.5002 5.6656 1.5002ZM9.9999 5.4543C10.4141 5.4543 10.7499 5.79008 10.7499 6.2043C10.7499 6.58399 10.4677 6.89779 10.1017 6.94745L9.98989 6.9543C9.57568 6.9543 9.23989 6.61851 9.23989 6.2043C9.23989 5.8246 9.52205 5.51081 9.88812 5.46114L9.9999 5.4543ZM9.98989 8.6271C10.3696 8.6271 10.6834 8.90925 10.733 9.27533L10.7399 9.3771V13.7961C10.7399 14.2103 10.4041 14.5461 9.98989 14.5461C9.6102 14.5461 9.2964 14.2639 9.24674 13.8979L9.23989 13.7961V9.3771C9.23989 8.96288 9.57568 8.6271 9.98989 8.6271Z"
        fill="#A4A4A4"
      />
    </svg>
  );
};

export default Icon;
