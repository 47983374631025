import React from "react";
import Star from "icons/Star";

const Bonuses = ({ bonusesCount, label }) => {
  return (
    <div className="bonuses">
      <Star />
      <span style={label ? { marginRight: ".4rem" } : null}>
        {bonusesCount}
      </span>
      {label}
    </div>
  );
};

export default Bonuses;
