import React from "react";

export const ArrowDown = () => {
  return (
    <svg
      width="362"
      height="202"
      viewBox="0 0 362 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M181 184L335 12L27 12L181 184Z"
        fill="#FFD250"
        stroke="#562C96"
        strokeWidth="24"
      />
    </svg>
  );
};
