import { configureStore } from "@reduxjs/toolkit";
import bonuses from "./slices/bonusesSlice";
import user from "./slices/userSlice";
import services from "./slices/serviceSlice";

export const store = configureStore({
  reducer: {
    bonuses,
    user,
    services,
  },
});
