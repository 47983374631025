import React, { memo, useCallback, useContext, useState } from "react";
import PrimaryButton from "components/buttons/PrimaryButton";
import SecondaryButton from "components/buttons/SecondaryButton";
import Checkbox from "components/formElements/Checkbox";
import { UserApi } from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";
import { popupProvider } from "contextProviders/popupProvider";
import { useNavigate } from "react-router-dom";
import { setAuthKey } from "redux/slices/userSlice";
import { useCookies } from "react-cookie";
import { ROUTES_PATH } from "constants/routesPath";
import { serviceSelect } from "redux/selectors/service";
import { IMaskInput } from "react-imask";

const ConfirmPhoneForm = memo(({ phone, codeSended }) => {
  const dispatch = useDispatch();
  const { setPopupContent, setPopupOpen } = useContext(popupProvider);
  const navigate = useNavigate();
  const { rules_link } = useSelector(serviceSelect);

  const [code, setCode] = useState("");
  const [promo, setPromo] = useState("");
  const [checked, setChecked] = useState(false);
  const [dataSending, setDataSending] = useState(false);
  const [cookie, setCookie] = useCookies(["auth_key", "user_id"]);

  const handleSubmit = () => {
    if (checked || codeSended === "have-code") {
      setDataSending(true);

      const data = { msisdn: phone.slice(1), password: code };

      if (promo !== "") {
        data.promocode = promo;
      }

      UserApi.login(data)
        .then((res) => {
          dispatch(setAuthKey(res.auth_key));
          dispatch(
            fetchUserInfo({ auth_key: res.auth_key, user_id: res.user_id })
          );
		  
		  const timestamp = new Date().getTime();
		  //expires after one year
		  const expire = timestamp + (60 * 60 * 24 * 1000 * 365 * 1);
		  const expireDate = new Date(expire);
		  
		  setCookie("auth_key", res.auth_key, {expires : expireDate, secure : false});
          setCookie("user_id", res.user_id, {expires : expireDate, secure : false});

          navigate(ROUTES_PATH.HOME);
        })
        .catch((e) => {
          setPopupContent({
            error: e.response?.data?.error,
          });
          setPopupOpen(true);
        })
        .finally(() => setDataSending(false));
    } else {
      setPopupContent({
        error: "Согласитесь с правилами",
      });
      setPopupOpen(true);
    }
  };

  const handleResend = useCallback(() => {
    UserApi.register({ msisdn: phone, confirm: 1 });
  }, []);

  return (
    <form className="confirm-phone-form">
      <div className="code">
        <IMaskInput
          mask={"00000"}
          radix="."
          value={code}
          unmask={true}
          onAccept={(value, mask) => setCode(value)}
          className={"primary-input"}
        />
      </div>
      {codeSended === "sended" && (
        <>
          <Checkbox
            checked={checked}
            id="rules"
            setChecked={setChecked}
            label={`Согласен с <a href=${rules_link} class='blue-link'>правилами</a>`}
          />

          <p className="registration__text">
            Соглашаясь, Вы принимаете правила (полная версия по ссылке),
            разрешаете обрабатывать и использовать свои персональные данные, с
            целью проведения исследований, осуществления сервисных коммуникаций
            и рекламных рассылок об акциях, товарах и услугах, в соответствии со
            152-ФЗ.
          </p>

          <div className="code">
            <IMaskInput
              mask={String}
              radix="."
              value={promo}
              unmask={true}
              onAccept={(value, mask) => setPromo(value)}
              className={"primary-input"}
              placeholder="Промокод"
            />
          </div>
        </>
      )}

      <PrimaryButton
        disabled={dataSending}
        long
        text={codeSended === "sended" ? "Отправить" : "Войти"}
        callback={handleSubmit}
      />
      {codeSended === "sended" && (
        <SecondaryButton
          text={"Код не получен, отправить повторно"}
          callback={handleResend}
        />
      )}
    </form>
  );
});

export default ConfirmPhoneForm;
