import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowDown } from "icons/ArrowDown";
import { SpinApi } from "utils/api";
import { Bear } from "icons/Bear";
import { SpinGift } from "icons/SpinGift";
import { PurcentPrize } from "icons/PurcentPrize";
import { SpinStar } from "icons/SpinStar";
import { giftProvider } from "../contextProviders/giftProvider";
import { popupProvider } from "../contextProviders/popupProvider";
import { BonusesApi } from "../utils/api";
import SpinButton from "./buttons/SpinButton";
import { ROUTES_PATH } from "constants/routesPath";
import { useSelector } from "react-redux";
import { userSelect } from "redux/selectors/user";

function randomInteger(min, max) {
  let rand = min + Math.random() * (max + 1 - min);
  return Math.floor(rand);
}

const SpinningWheel = () => {
  const navigate = useNavigate();
  const { user_info, auth_key } = useSelector(userSelect);
  const { setGift } = useContext(giftProvider);
  const { setPopupContent, setPopupOpen } = useContext(popupProvider);
  const { id } = useParams();

  const [spinPrice, setSpinPrice] = useState("...");
  const [disabled, setDisabled] = useState(false);

  const spinningWheelRef = useRef();

  const handleSpin = (e) => {
    e.preventDefault();

    if (user_info) {
      setDisabled(true);
      SpinApi.spin({
        bonus_id: id,
        user_id: user_info.id,
        auth_key,
      })
        .then((res) => {
          setGift(res.gift);

          const loseArray = [15, 75, 135, 185, 245, 305];
          const winArray = [45, 105, 165, 225, 285, 355];

          if (res.result === 0) {
            spinningWheelRef.current.style.transform = `translateX(-50%) rotate(${
              720 + loseArray[randomInteger(0, 5)]
            }deg)`;

            setTimeout(() => {
              setDisabled(false);
              navigate(ROUTES_PATH.SPINNING_WHEEL_LOSE);
            }, 4500);
          } else {
            spinningWheelRef.current.style.transform = `translateX(-50%) rotate(${
              720 + winArray[randomInteger(0, 5)]
            }deg)`;

            setTimeout(() => {
              setDisabled(false);
              navigate(ROUTES_PATH.SPINNING_WHEEL_AWARD);
            }, 4500);
          }
        })
        .catch((e) => {
          setDisabled(false);
          if (e.response.status === 400) {
            setPopupContent({
              title: e.response.data.error,
              children: (
                <>
                  <SpinButton
                    callback={() => setPopupOpen(false)}
                    text={"Закрыть"}
                  />
                </>
              ),
            });
            setPopupOpen(true);
          }
        });
    } else {
      navigate(ROUTES_PATH.REGISTRATION);
    }
  };

  useEffect(() => {
    BonusesApi.getBonus({
      user_id: localStorage.getItem("user_id"),
      id: id,
    })
      .then((res) => setSpinPrice(res.price))
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <div className="spinning-wheel__button">
        <p>
          <b>Крути колесо и выиграй приз!</b> Стоимость одного вращения{" "}
          {spinPrice} бонусов
        </p>

        <a href="https://jetcrm.ru/gudvin/prizesvoyage.pdf" target="_blank">
          Правила розыгрыша
        </a>

        <SpinButton
          disabled={disabled}
          text={`Крутить`}
          callback={handleSpin}
        />
      </div>

      <div className="spinning-wheel__arrow">
        <ArrowDown />
      </div>

      <div ref={spinningWheelRef} className="spinning-wheel">
        <div className="spinning-wheel__wrapper">
          <div className="one"></div>

          <div className="two">
            <Bear />
          </div>

          <div className="three"></div>

          <div className="four">
            <SpinGift />
          </div>

          <div className="five"></div>

          <div className="six">
            <PurcentPrize />
          </div>

          <div className="seven"></div>

          <div className="eight">
            <SpinStar />
          </div>

          <div className="nine"></div>

          <div className="ten">
            <Bear />
          </div>

          <div className="eleven"></div>

          <div className="twelwe">
            <SpinGift />
          </div>
        </div>
      </div>
    </>
  );
};

export default SpinningWheel;
