import {
  Routes,
  Route,
  useLocation,
  ScrollRestoration,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import { ROUTES_PATH } from "constants/routesPath";
import Home from "pages/Home";
import Registration from "pages/Registration";
import Profile from "pages/Profile";
import Compliment from "pages/Compliment";
import { popupProvider } from "contextProviders/popupProvider";
import { giftProvider } from "contextProviders/giftProvider";
import { useEffect, useState } from "react";
import Popup from "components/Popup";
import Account from "pages/Account";
import AboutProgram from "pages/AboutProgram";
import UserCompliments from "pages/account/UserCompliments";
import InviteFriend from "pages/account/InviteFriend";
import Story from "pages/account/Story";
import Contact from "pages/account/Contact";
import { Provider } from "react-redux";
import { store } from "redux/store";
import Wrapper from "components/Wrapper";
import UserCompliment from "pages/account/UserCompliment";
import { CookiesProvider } from "react-cookie";
import DesktopScreen from "components/DesktopScreen";
import { useMedia } from "react-use";
import WheelPage from "pages/WheelPage";
import Lose from "pages/Lose";
import Award from "pages/Award";

const popupInitialContent = {
  title: "",
  icon: null,
  acceptButton: null,
  cancelButton: null,
  children: null,
};

function Root({ children }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(popupInitialContent);
  const [gift, setGift] = useState(null);
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(true);
  const desktop = useMedia("(min-width: 768px)");

  const toggleVisibility = () => setVisible(false);

  return (
    <CookiesProvider>
      <Provider store={store}>
        <giftProvider.Provider
          value={{
            gift,
            setGift,
          }}
        >
          <popupProvider.Provider
            value={{
              popupOpen,
              setPopupOpen,
              popupContent,
              setPopupContent,
            }}
          >
            <DesktopScreen
              visible={visible}
              toggleVisibility={toggleVisibility}
            />
            {(!desktop || !visible) && (
              <Wrapper
                white={
                  pathname.includes("compliment/") || pathname.includes("news/")
                }
              >
                <Popup
                  open={popupOpen}
                  setOpen={setPopupOpen}
                  popupContent={popupContent}
                />
                <Outlet />

                <ScrollRestoration
                  getKey={(location, matches) => {
                    return location.pathname;
                  }}
                />
              </Wrapper>
            )}
          </popupProvider.Provider>
        </giftProvider.Provider>
      </Provider>
    </CookiesProvider>
  );
}

export default Root;
