import React from "react";

const Icon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 1L11.4084 6.87336L17.584 6.87336L12.5878 10.5033L14.4962 16.3766L9.5 12.7467L4.50383 16.3766L6.41219 10.5033L1.41602 6.87336L7.59163 6.87336L9.5 1Z"
        fill="#BB2A7A"
        stroke="#BB2A7A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
