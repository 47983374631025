import React from "react";

const Icon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25027 13.2697C9.25027 12.8557 9.58627 12.5137 10.0003 12.5137C10.4143 12.5137 10.7503 12.8447 10.7503 13.2587V13.2697C10.7503 13.6837 10.4143 14.0197 10.0003 14.0197C9.58627 14.0197 9.25027 13.6837 9.25027 13.2697Z"
        fill="#CE3120"
      />
      <path
        d="M2.79569 17.418H17.2777C18.4247 17.358 19.3067 16.381 19.2477 15.234C19.2347 15.002 19.1847 14.773 19.0967 14.559L11.8197 1.82298C11.2637 0.817977 9.99869 0.453977 8.99369 1.01098C8.65169 1.19898 8.36969 1.48098 8.18069 1.82298L0.903687 14.559C0.471687 15.623 0.983687 16.835 2.04669 17.268C2.26269 17.355 2.49069 17.405 2.72269 17.418"
        stroke="#CE3120"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.9899 10.3964V7.29639"
        stroke="#CE3120"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
