import React, { memo, useCallback } from "react";

const Checkbox = memo(({ checked, label, setChecked, id }) => {
  const handleChange = useCallback(() => {
    setChecked(!checked);
  }, [checked]);

  return (
    <div className="custom-checkbox">
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }}></label>
    </div>
  );
});

export default Checkbox;
