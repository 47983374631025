import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceApi } from "utils/api";

export const fetchService = createAsyncThunk(
  "service/fetchService",
  async () => {
    const data = await ServiceApi.getData();

    return data;
  }
);
