import React, { memo, useCallback } from "react";

const TextArea = memo(({ label, id, value, setValue }) => {
  const handleChange = useCallback((e) => setValue(e.target.value), []);

  return (
    <div className="form-textarea form-input">
      <label htmlFor={id}>{label}</label>
      <textarea
        id={id}
        onChange={(e) => handleChange(e)}
        value={value}
      ></textarea>
    </div>
  );
});

export default TextArea;
