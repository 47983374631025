import React, { useState } from "react";
import Logo from "icons/Logo";
import PrimaryButton from "components/buttons/PrimaryButton";

const DesktopScreen = ({ toggleVisibility, visible }) => {
  return (
    <>
      {visible && (
        <section className="desktop">
          <div className="desktop__container">
            <div className="desktop__right">
              <div className="desktop__logo">
                <Logo />
              </div>

              <h1 className="desktop__title">Уважаемые посетители!</h1>

              <p className="desktop__text">
                Сайт программы лояльности ТЦ Галерея Вояж адаптирован для
                мобильных устройств. Информируем Вас, что для ПК работает в
                режиме ограниченной функциональности. 
              </p>

              <PrimaryButton
                long
                text={"Перейти на сайт"}
                callback={toggleVisibility}
              />
            </div>

            <div className="desktop__left">
              <img src="/img/desktop-picture.jpg" alt="Изображение ТЦ" />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default DesktopScreen;
