import React, { memo } from "react";
import GoBackIcon from "icons/GoBack";

const GoBackButton = memo(({ callback }) => {
  return (
    <button onClick={callback} className="go-back icon-button">
      <GoBackIcon />
    </button>
  );
});

export default GoBackButton;
