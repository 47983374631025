import React, { memo, useCallback } from "react";

const Categories = memo(({ items, setActiveCategory, activeCategory }) => {
  const handleChangeCategory = useCallback((id) => setActiveCategory(id), []);

  return (
    <ul className="categories">
      {items.map((item, key) => (
        <li
          onClick={() => handleChangeCategory(item.id)}
          className={`categories__item ${
            activeCategory === item.id ? "categories__item--active" : ""
          }`}
          key={key}
        >
          <div className="categories__icon">
            {item.icon && item.icon}
            {item.image && <img src={item.image} alt="Иконка категории" />}
            {item.count && (
              <span className="categories__count">{item.count}</span>
            )}
          </div>
          <span className="categories__label">{item.title}</span>
        </li>
      ))}
    </ul>
  );
});

export default Categories;
