import React, { useState } from "react";
import Logo from "icons/Logo";
import Frame from "icons/HomePageFrame";
import Burger from "components/Burger";
import { ROUTES_PATH } from "constants/routesPath";
import { Link, useLocation } from "react-router-dom";

const menuItems = [
  {
    label: "Главная",
    href: "https://galleryv.ru/main",
  },

  {
    label: "Магазины",
    href: "https://galleryv.ru/shops",
  },

  {
    label: "Новости",
    href: "https://galleryv.ru/news",
  },

  {
    label: "Программа лояльности",
    href: "www.voyagepromo.ru",
  },

  {
    label: "О тц",
    href: "https://galleryv.ru/about",
  },

  {
    label: "Арендаторам",
    href: "https://galleryv.ru/rent",
  },

  {
    label: "Контакты",
    href: "https://galleryv.ru/contacts",
  },
];

const Hero = () => {
  const { pathname } = useLocation();
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenuActive = () => {
    setMenuActive(!menuActive);
    document.body.classList.toggle("lock");
  };

  return (
    <section className="hero">
      <div className="container">
        <div
          className={`hero__line ${menuActive ? "hero__line--active" : ""}`}
        ></div>
        <Burger isActive={menuActive} callback={toggleMenuActive} />
        <ul className={`hero__menu ${menuActive ? "hero__menu--active" : ""}`}>
          {menuItems.map((item, key) => (
            <li
              className="hero__menu-item"
              key={key}
              onClick={toggleMenuActive}
            >
              <a href={item.href} target="_blank">
                {item.label}
              </a>
            </li>
          ))}
        </ul>

        {pathname !== ROUTES_PATH.HOME ? (
          <Link to={ROUTES_PATH.HOME} className="hero__logo">
            <Logo />
          </Link>
        ) : (
          <div
            className={`hero__logo ${menuActive ? "hero__logo--active" : ""}`}
          >
            <Logo />
          </div>
        )}
      </div>
    </section>
  );
};

export default Hero;
