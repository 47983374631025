import SectionTitle from "components/titles/SectionTitle";
import GoBackButton from "components/GoBackButton";
import { ROUTES_PATH } from "constants/routesPath";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { serviceSelect } from "redux/selectors/service";

const AboutProgram = () => {
  const navigate = useNavigate();
  const { about } = useSelector(serviceSelect);

  const goBackCallback = () => {
    navigate(ROUTES_PATH.ACCOUNT);
  };

  return (
    <main className="main">
      <section className="about-program">
        <div className="container">
          <GoBackButton callback={goBackCallback} />

          <div className="about-program__content">
            <SectionTitle
              extraClass={"about-program__title"}
              text={"О программе"}
            />

            <div dangerouslySetInnerHTML={{ __html: about }}></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutProgram;
