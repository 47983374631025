import React, { useCallback, useContext, useState } from "react";
import GoBackButton from "components/GoBackButton";
import SectionTitle from "components/titles/SectionTitle";
import { ROUTES_PATH } from "constants/routesPath";
import SectionSubtitle from "components/titles/SectionSubtitle";
import RegistrationForm from "components/forms/RegistrationForm";
import ConfirmPhoneForm from "components/forms/ConfirmPhoneForm";
import { useNavigate } from "react-router-dom";
import { UserApi } from "utils/api";
import { popupProvider } from "contextProviders/popupProvider";

const RegistrationContent = () => {
  const { setPopupOpen, setPopupContent } = useContext(popupProvider);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dataSending, setDataSending] = useState(false);
  const [codeSended, setCodeSended] = useState(null); // null | sended | haveCode
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    setDataSending(true);

    UserApi.register({
      msisdn: phone.slice(1),
      fullname: name,
      email,
      confirm: 1,
    })
      .then(() => setCodeSended("sended"))
      .catch((e) => {
        setPopupContent({
          error: e.response.data.error,
        });
        setPopupOpen(true);
      })
      .finally(() => setDataSending(false));
  }, [phone, name, email]);

  const handleHaveCode = useCallback(() => {
    if (phone.length === 11) {
      setCodeSended("have-code");
    } else {
      setPopupContent({
        error: "Введите номер телефона",
      });
      setPopupOpen(true);
    }
  }, [phone]);

  const goBackCallback = useCallback(() => {
    if (!codeSended) {
      navigate(ROUTES_PATH.HOME);
    } else {
      setCodeSended(false);
    }
  }, [codeSended]);

  return (
    <section className="registration">
      <div className="container">
        <GoBackButton callback={goBackCallback} />
        <div className="registration__content">
          <SectionTitle
            extraClass={"registration__title"}
            text={codeSended === "have-code" ? "Вход" : "Регистрация"}
            center
          />

          <SectionSubtitle
            extraClass={"registration__subtitle"}
            text={`${
              codeSended
                ? `На номер телефона ${phone} был отправлен код`
                : "Введите номер телефона и мы отправим вам код"
            }`}
            center
          />

          {!codeSended ? (
            <RegistrationForm
              phone={phone}
              setPhone={setPhone}
              name={name}
              setName={setName}
              primaryAction={handleSubmit}
              secondaryAction={handleHaveCode}
              dataSending={dataSending}
              email={email}
              setEmail={setEmail}
            />
          ) : (
            <ConfirmPhoneForm codeSended={codeSended} phone={phone} />
          )}
        </div>
      </div>
    </section>
  );
};

export default RegistrationContent;
