export default function Burger({ isActive, callback }) {
  return (
    <div
      className={`icon-button ${isActive ? "icon-button--active" : ""}`}
      onClick={callback}
    >
      <div className={`burger ${isActive ? "burger--active" : ""}`}>
        <span></span>
      </div>
    </div>
  );
}
