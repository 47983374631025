import { createSlice } from "@reduxjs/toolkit";
import { fetchUserInfo } from "redux/asyncActions/fetchUserInfo";

const initialState = {
  user_info: {},
  auth_key: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAuthKey(state, action) {
      state.auth_key = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.user_info = action.payload;
    });

    builder.addCase(fetchUserInfo.rejected, (state) => {
      state.user_info = [];
    });
  },
});

export const { setAuthKey } = userSlice.actions;

export default userSlice.reducer;
