import React from "react";

const Icon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99979 0.000488281C4.47657 0.000488281 -0.000213623 4.47727 -0.000213623 10.0005C-0.000213623 15.5237 4.47657 20.0005 9.99979 20.0005C15.523 20.0005 19.9998 15.5237 19.9998 10.0005C19.9998 4.47727 15.523 0.000488281 9.99979 0.000488281ZM9.99979 1.50049C14.6946 1.50049 18.4998 5.3057 18.4998 10.0005C18.4998 14.6953 14.6946 18.5005 9.99979 18.5005C5.305 18.5005 1.49979 14.6953 1.49979 10.0005C1.49979 5.3057 5.305 1.50049 9.99979 1.50049ZM10.4045 5.74512C10.3549 5.37904 10.0411 5.09689 9.66139 5.09689C9.24717 5.09689 8.91139 5.43267 8.91139 5.84689V10.6939L8.91974 10.8056C8.95288 11.0257 9.08272 11.222 9.27715 11.338L13.0471 13.587L13.1381 13.6332C13.4779 13.7781 13.881 13.6532 14.0755 13.3271L14.1217 13.2362C14.2666 12.8964 14.1417 12.4933 13.8156 12.2988L10.411 10.267L10.4114 5.84689L10.4045 5.74512Z"
        fill="black"
      />
    </svg>
  );
};

export default Icon;
