import React from "react";
import SpinningWheel from "components/SpinningWheel";

const WheelPage = () => {
  return (
    <div className="container">
      <div className="test">
        <div className="test__header">
          <div className="test__round test__round--left"></div>
          <div className="test__line"></div>
          <div className="test__round test__round--right"></div>
        </div>
        <div className="test__content">
          <div>
            <div className="form-submit">
              <SpinningWheel />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WheelPage;
