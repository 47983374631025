import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBonusesCategories,
  fecthBonuses,
} from "redux/asyncActions/fetchBonuses";

const initialState = {
  categories: [],
  bonuses: [],
};

const bonusesSlice = createSlice({
  name: "bonuses",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fecthBonuses.fulfilled, (state, action) => {
      state.bonuses = action.payload;
    });

    builder.addCase(fecthBonuses.rejected, (state) => {
      state.bonuses = [];
    });

    builder.addCase(fetchBonusesCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });

    builder.addCase(fetchBonusesCategories.rejected, (state) => {
      state.categories = [];
    });
  },
});

export default bonusesSlice.reducer;
