import ProfileContent from "layout/profile/ProfileContent";
import React from "react";

const Profile = () => {
  return (
    <main className="main">
      <ProfileContent />
    </main>
  );
};

export default Profile;
