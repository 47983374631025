import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowIcon from "icons/GoBack";
import { ROUTES_PATH } from "constants/routesPath";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

const AccountNavigation = () => {
  const { id } = useParams();
  const { user, services } = useSelector((state) => state);
  const { user_info } = user;
  const { service } = services;
  const navigate = useNavigate();

  const [cookie, setCookie, removeCookie] = useCookies();

  const menuItems = [
    {
      label: "О программе",
      href: ROUTES_PATH.ABOUT_PROGRAM,
    },

    {
      label: "Мои комплименты",
      href: `/account/${id}/compliments`,
      count: user_info.bonus_new,
    },

    {
      label: "Пригласи друга",
      href: `/account/${id}/invite-friend`,
      message: `+${service.bonus_friend} бонусов`,
    },

    {
      label: "История начислений",
      href: `/account/${id}/story`,
    },

    {
      label: "Обратная связь",
      href: `/account/${id}/contact`,
    },
  ];

  const logout = () => {
    removeCookie("auth_key");
    navigate(ROUTES_PATH.HOME);
    window.location.reload();
  };

  return (
    <section className="account-navigation">
      <div className="container">
        <ul className="account-navigation__list">
          {menuItems.map((item, key) => (
            <li key={key} className="account-navigation__item">
              <Link to={item.href}>{item.label}</Link>

              {item.count > 0 && (
                <span className="account-navigation__item-count">
                  {item.count}
                </span>
              )}

              {item.message && (
                <span className="account-navigation__item-message">
                  {item.message}
                </span>
              )}
              <ArrowIcon />
            </li>
          ))}
        </ul>

        <Link to={ROUTES_PATH.HOME} className="account-navigation__button">
          На главную
        </Link>

        <button className="account-navigation__button" onClick={logout}>
          Выйти из профиля
        </button>
      </div>
    </section>
  );
};

export default AccountNavigation;
