import React, { useContext } from "react";
import { giftProvider } from "contextProviders/giftProvider";
import SpinButton from "../components/buttons/SpinButton";
import { ROUTES_PATH } from "constants/routesPath";

const Award = () => {
  const { gift } = useContext(giftProvider);

  return (
    <div className="container">
      <div className="test test--award" style={{ marginBottom: "3rem" }}>
        <div className="test__content test__content--award">
          <div className="award">
            <h2 className="award__title award__title--uppercase">
              Поздравляем
            </h2>
            <h4 className="award__subtitle">{gift?.title}</h4>

            <div className="award__image">
              <img src={gift?.image} alt="Приз" />
            </div>

            <p className="award__message award__message--less-margin">
              Пожалуйста, получите Ваш подарок на инфостойке.
            </p>

            <div className="spinning-wheel__button">
              <SpinButton text={`Вернуться назад`} href={ROUTES_PATH.HOME} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Award;
